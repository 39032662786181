import axios from 'axios'

export default {
  async all() {
    const response = await axios.get('odometer')
    return response
  },

  async dashboard({ startDate, endDate, userId }) {
    const response = await axios.get(
      `odometer/dashboard?startDate=${startDate}&endDate=${endDate}&userId=${userId}`,
    )
    return response
  },

  async delete(id) {
    const response = await axios.delete(`odometer/${id}`)
    return response
  },
}
