<template>
  <v-container>
    <v-card>
      <DashboardFilter @search="search" />
    </v-card>
    <v-card>
      <v-row align="center" justify="center">
        <base-loading v-if="loading" />

        <v-col
          v-if="dashboards !== null && !loading"
          cols="8"
          class="graficoConsultor"
        >
          <div class="chart-table">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <h2
                  class="text-xl-h4 font-weight-medium"
                  style="color: #1b2565"
                >
                  Informações do Consultor
                </h2>
                <base-bar-graph
                  :chart-data="{
                    labels: renderLabels(dashboard, month),
                    datasets: renderGraphDataset(dashboards),
                  }"
                  :style="styles"
                  :stacked="false"
                />
              </v-col>
            </v-row>
          </div>
          <div class="chart-table">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <TableGraph
                  class="mt-5"
                  :items="renderTable(dashboards)"
                  :headers="renderTableHeader()"
                  :items-per-page="15"
                  :v-fixed-columns="true"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col
          v-else-if="!loading"
          cols="12"
          sm="12"
          md="12"
          align="center"
          justify="center"
        >
          <v-card-text>
            Sem dados para exibir, por favor, selecione um período válido e um
            consultor.
            <v-icon>mdi-information</v-icon>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
import TableGraph from '../../../components/base/TableGraph.vue'
import odometerService from '../../../services/api/odometer'
import { GRAPH_COLORS } from '../../../utils/constants'
import DashboardFilter from '../gestor/filter'
export default {
  name: 'OdometerDashboard',

  components: {
    DashboardFilter,
    TableGraph,
  },

  data() {
    return {
      styles: {
        backgroundColor: '#f1f1f1',
      },

      loading: false,

      dashboards: null,
    }
  },

  methods: {
    search(form) {
      this.loading = true
      odometerService
        .dashboard({
          userId: form.user.user_id,
          startDate: form.dataInicio,
          endDate: form.dataFim,
        })
        .then(response => {
          this.dashboards = response.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false

          this.Toast().fire({
            icon: 'error',
            title: 'Erro ao buscar dados!',
          })
        })
    },

    renderLabels() {
      const length = 31
      const days = Array.from({ length }, (_, i) => i + 1)
      return [...days, 'total']
    },

    renderTableHeader() {
      const length = 31
      const days = Array.from({ length }, (_, i) => i + 1)

      return [
        {
          text: 'Mês',
          value: 'mes',
        },
        ...days.map(day => ({
          text: day,
          value: `dia-${day}`,
        })),
        {
          text: 'Total',
          value: 'total',
        },
      ]
    },

    renderTable(data) {
      const rows = []

      Object.keys(data).map(year => {
        Object.keys(data[year]).map(month => {
          const mes = moment(month, 'MM').format('MMMM') + ' de ' + year

          const row = {
            mes: mes,
          }

          Object.keys(data[year][month]).forEach(key => {
            const day = Number(key) + 1

            row[`dia-${day}`] = Number(data[year][month][key]).toFixed(2)
          })

          row.total = data[year][month]['total']

          rows.push(row)
        })
      })

      return rows
    },

    renderGraphDataset(data) {
      const datasets = []

      Object.keys(data).map(year => {
        Object.keys(data[year]).map(month => {
          const mes = moment(month, 'MM').format('MM') + ' de ' + year

          const dataset = {
            data: Object.values(data[year][month]),
            label: mes,
            backgroundColor: GRAPH_COLORS[datasets.length],
          }

          datasets.push(dataset)
        })
      })

      return datasets
    },
  },
}
</script>
