<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="700"
      >
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Lançamento de percurso</v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-row class="d-block">
                    <v-col>
                      <v-icon class="mr-2"> mdi-launch </v-icon>
                      <span class="font-weight-medium">Lançamento inicial</span>
                    </v-col>

                    <v-col>
                      <v-icon class="mr-2">mdi-calendar</v-icon>
                      <span class="font-weight-medium"
                        >{{ moment(odometer.startDate).format('LLL') }}
                      </span>
                    </v-col>

                    <v-col>
                      <v-icon class="mr-2">mdi-car</v-icon>
                      <span class="font-weight-medium"
                        >{{ odometer.startKilometers }} km</span
                      >
                    </v-col>

                    <v-col>
                      <v-btn
                        icon
                        class="ml-0"
                        target="_blank"
                        style="width: 25px"
                        :href="
                          googleMapsUrl(
                            odometer.startLocationLatitude,
                            odometer.startLocationLongitude,
                          )
                        "
                      >
                        <v-icon size="25" color="grey">mdi-map-marker</v-icon>
                      </v-btn>
                      <span class="font-weight-medium"
                        >{{ odometer.startLocationLatitude }}
                        <v-icon class="mr-2" size="18">mdi-arrow-right</v-icon>
                        {{ odometer.startLocationLongitude }}</span
                      >
                    </v-col>

                    <v-col>
                      <v-icon class="mr-2">mdi-car-sports</v-icon>
                      <span class="font-weight-medium"
                        >Imagem hodômetro inicial</span
                      >
                      <v-img
                        v-if="odometer.startImageId"
                        :src="getImagem(odometer.startImageId)"
                        width="250"
                        height="200"
                        contain
                        class="rounded-lg"
                      >
                        <template v-slot:placeholder>
                          <div
                            class="d-flex align-center justify-center fill-height"
                          >
                            <v-progress-circular
                              color="primary-lighten-4"
                              indeterminate
                            ></v-progress-circular>
                          </div>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <v-row class="d-block">
                    <v-col>
                      <v-icon class="mr-2"> mdi-launch </v-icon>
                      <span class="font-weight-medium">Lançamento final</span>
                    </v-col>
                    <v-col>
                      <v-icon class="mr-2">mdi-calendar</v-icon>
                      <span class="font-weight-medium"
                        >{{ moment(odometer.endDate).format('LLL') }}
                      </span>
                    </v-col>

                    <v-col>
                      <v-icon class="mr-2">mdi-car</v-icon>
                      <span class="font-weight-medium"
                        >{{ odometer.endKilometers }} km</span
                      >
                    </v-col>

                    <v-col>
                      <v-btn
                        icon
                        class="ml-0"
                        style="width: 25px"
                        target="_blank"
                        :href="
                          googleMapsUrl(
                            odometer.endLocationLatitude,
                            odometer.endLocationLongitude,
                          )
                        "
                      >
                        <v-icon size="25" color="grey">mdi-map-marker</v-icon>
                      </v-btn>
                      <span class="font-weight-medium"
                        >{{ odometer.endLocationLatitude }}
                        <v-icon class="mr-2" size="18">mdi-arrow-right</v-icon>
                        {{ odometer.endLocationLongitude }}</span
                      >
                    </v-col>

                    <v-col>
                      <v-icon class="mr-2">mdi-car-sports</v-icon>
                      <span class="font-weight-medium"
                        >Imagem hodômetro final</span
                      >
                      <v-img
                        v-if="odometer.endImageId"
                        :src="getImagem(odometer.endImageId)"
                        width="250"
                        height="200"
                        contain
                        class="rounded-lg"
                      >
                        <template v-slot:placeholder>
                          <div
                            class="d-flex align-center justify-center fill-height"
                          >
                            <v-progress-circular
                              color="primary-lighten-4"
                              indeterminate
                            ></v-progress-circular>
                          </div>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="d-block">
                <v-col>
                  <v-icon class="mr-2"> mdi-launch </v-icon>
                  <span class="font-weight-medium">Quilometragem rodada.</span>
                </v-col>

                <v-col>
                  <v-icon class="mr-2">mdi-car</v-icon>
                  <span class="font-weight-medium"
                    >{{ odometer.total_km ? odometer.total_km : 0 }} km</span
                  >
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="red" @click="dialog = false">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { ENV_APP } from '../../../../env'
export default {
  name: 'OdometerView',

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    odometer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: this.value,
    }
  },

  watch: {
    value() {
      this.dialog = this.value
    },

    dialog() {
      this.$emit('input', this.dialog)
    },
  },

  methods: {
    getImagem(id) {
      return `${ENV_APP.apiUri}/download-image/${id}`
    },

    googleMapsUrl(latitude, longitude) {
      return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    },
  },
}
</script>
