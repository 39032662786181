<template>
  <v-card class="ma-3">
    <v-tabs centered>
      <v-tab icon @click="tabs = 0">
        <v-icon>mdi-car</v-icon>
        Lançamento de percurso</v-tab
      >
      <v-tab
        v-if="
          $user.get().role === 'admin' || $user.get().role === 'consultoria'
        "
        @click="tabs = 1"
      >
        <v-icon>mdi-view-dashboard</v-icon>
        Dashboard</v-tab
      >
    </v-tabs>
    <base-loading v-if="loading" />
    <v-tabs-items v-else v-model="tabs">
      <v-tab-item>
        <crud-list
          v-model="desserts"
          :slots="[
            'item.startDate',
            'item.total_km',
            'item.endKilometers',
            'item.startKilometers',
          ]"
          :headers="headers"
          :actions="actions"
        >
          <template v-slot:[`item.startDate`]="{ item }">
            {{ item.startDate ? moment(item.startDate).format('LLL') : ' - ' }}
          </template>
          <template v-slot:[`item.total_km`]="{ item }">
            {{ item.total_km ? item.total_km + ' km' : ' - ' }}
          </template>
          <template v-slot:[`item.endKilometers`]="{ item }">
            {{ item.endKilometers ? item.endKilometers + ' km' : ' - ' }}
          </template>
          <template v-slot:[`item.startKilometers`]="{ item }">
            {{ item.startKilometers ? item.startKilometers + ' km' : ' - ' }}
          </template>
        </crud-list>
      </v-tab-item>
      <v-tab-item>
        <Dashboard />
      </v-tab-item>
    </v-tabs-items>

    <OdometerView v-model="dialog" :odometer="odometer" />
  </v-card>
</template>
<script>
import OdometerService from '../../../services/api/odometer'
import Dashboard from './OdometerDashboard'
import OdometerView from './OdometerView'

export default {
  components: {
    OdometerView,
    Dashboard,
  },
  props: {},
  data() {
    return {
      tabs: 0,

      actions: [
        {
          icon: 'mdi-delete',
          color: 'red darken-3',
          tooltip: 'Excluir',
          click: item => {
            this.Swal.fire({
              title: 'Deseja realmente excluir este lançamento?',
              text: 'Esta ação não poderá ser desfeita!',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sim, excluir!',
              cancelButtonText: 'Cancelar',
            }).then(result => {
              if (result.value) {
                OdometerService.delete(item.id).then(() => {
                  this.all()
                  this.Swal.fire(
                    'Excluído!',
                    'O lançamento foi excluído com sucesso.',
                    'success',
                  )
                })
              }
            })
          },
        },
        {
          icon: 'mdi-eye',
          color: 'blue darken-3',
          tooltip: 'Visualizar',
          click: item => {
            this.odometer = item
            this.dialog = true
          },
        },
      ],

      desserts: [],

      loading: false,
      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        { text: 'Usuário', value: 'user.name', filterable: true },
        { text: 'Data de lançamento', value: 'startDate' },
        { text: 'Km Inicial', value: 'startKilometers' },
        { text: 'Km Final', value: 'endKilometers' },
        { text: 'Km Percorrido', value: 'total_km' },
      ],

      dialog: false,
      odometer: {},
    }
  },

  created() {
    this.all()
  },

  methods: {
    all() {
      this.loading = true
      OdometerService.all().then(response => {
        this.desserts = response.data.map(item => {
          const startKilometers = Number(item.startKilometers)
          const endKilometers = Number(item.endKilometers)

          item.total_km = endKilometers
            ? Number(endKilometers - startKilometers).toFixed(2)
            : 0
          return item
        })
        this.loading = false
      })
    },
  },
}
</script>

<style>
.v-tab--active .v-icon {
  color: #003677 !important;
}
</style>
