var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Lançamento de percurso")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"d-block"},[_c('v-col',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-launch ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Lançamento inicial")])],1),_c('v-col',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-calendar")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.moment(_vm.odometer.startDate).format('LLL'))+" ")])],1),_c('v-col',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-car")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.odometer.startKilometers)+" km")])],1),_c('v-col',[_c('v-btn',{staticClass:"ml-0",staticStyle:{"width":"25px"},attrs:{"icon":"","target":"_blank","href":_vm.googleMapsUrl(
                          _vm.odometer.startLocationLatitude,
                          _vm.odometer.startLocationLongitude,
                        )}},[_c('v-icon',{attrs:{"size":"25","color":"grey"}},[_vm._v("mdi-map-marker")])],1),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.odometer.startLocationLatitude)+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v("mdi-arrow-right")]),_vm._v(" "+_vm._s(_vm.odometer.startLocationLongitude))],1)],1),_c('v-col',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-car-sports")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Imagem hodômetro inicial")]),(_vm.odometer.startImageId)?_c('v-img',{staticClass:"rounded-lg",attrs:{"src":_vm.getImagem(_vm.odometer.startImageId),"width":"250","height":"200","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center fill-height"},[_c('v-progress-circular',{attrs:{"color":"primary-lighten-4","indeterminate":""}})],1)]},proxy:true}],null,false,1656589907)}):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"d-block"},[_c('v-col',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-launch ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Lançamento final")])],1),_c('v-col',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-calendar")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.moment(_vm.odometer.endDate).format('LLL'))+" ")])],1),_c('v-col',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-car")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.odometer.endKilometers)+" km")])],1),_c('v-col',[_c('v-btn',{staticClass:"ml-0",staticStyle:{"width":"25px"},attrs:{"icon":"","target":"_blank","href":_vm.googleMapsUrl(
                          _vm.odometer.endLocationLatitude,
                          _vm.odometer.endLocationLongitude,
                        )}},[_c('v-icon',{attrs:{"size":"25","color":"grey"}},[_vm._v("mdi-map-marker")])],1),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.odometer.endLocationLatitude)+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v("mdi-arrow-right")]),_vm._v(" "+_vm._s(_vm.odometer.endLocationLongitude))],1)],1),_c('v-col',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-car-sports")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Imagem hodômetro final")]),(_vm.odometer.endImageId)?_c('v-img',{staticClass:"rounded-lg",attrs:{"src":_vm.getImagem(_vm.odometer.endImageId),"width":"250","height":"200","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center fill-height"},[_c('v-progress-circular',{attrs:{"color":"primary-lighten-4","indeterminate":""}})],1)]},proxy:true}],null,false,1656589907)}):_vm._e()],1)],1)],1)],1),_c('v-row',{staticClass:"d-block"},[_c('v-col',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-launch ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Quilometragem rodada.")])],1),_c('v-col',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-car")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.odometer.total_km ? _vm.odometer.total_km : 0)+" km")])],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Fechar")])],1)],1)]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }