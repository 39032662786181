var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-3"},[_c('v-tabs',{attrs:{"centered":""}},[_c('v-tab',{attrs:{"icon":""},on:{"click":function($event){_vm.tabs = 0}}},[_c('v-icon',[_vm._v("mdi-car")]),_vm._v(" Lançamento de percurso")],1),(
        _vm.$user.get().role === 'admin' || _vm.$user.get().role === 'consultoria'
      )?_c('v-tab',{on:{"click":function($event){_vm.tabs = 1}}},[_c('v-icon',[_vm._v("mdi-view-dashboard")]),_vm._v(" Dashboard")],1):_vm._e()],1),(_vm.loading)?_c('base-loading'):_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',[_c('crud-list',{attrs:{"slots":[
          'item.startDate',
          'item.total_km',
          'item.endKilometers',
          'item.startKilometers',
        ],"headers":_vm.headers,"actions":_vm.actions},scopedSlots:_vm._u([{key:`item.startDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.startDate ? _vm.moment(item.startDate).format('LLL') : ' - ')+" ")]}},{key:`item.total_km`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.total_km ? item.total_km + ' km' : ' - ')+" ")]}},{key:`item.endKilometers`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.endKilometers ? item.endKilometers + ' km' : ' - ')+" ")]}},{key:`item.startKilometers`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.startKilometers ? item.startKilometers + ' km' : ' - ')+" ")]}}],null,true),model:{value:(_vm.desserts),callback:function ($$v) {_vm.desserts=$$v},expression:"desserts"}})],1),_c('v-tab-item',[_c('Dashboard')],1)],1),_c('OdometerView',{attrs:{"odometer":_vm.odometer},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }